import { Component, NgZone } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { FormControl, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { changeLanguage } from './state/language.actions';
import { Platform } from '@angular/cdk/platform';
import { UserActions } from './state/user/user.actions';
import { Router } from '@angular/router';
import { selectCurrentCompany } from './state/user/user.reducer';
import { skip, filter, distinctUntilKeyChanged } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  email = new FormControl('Email address', [
    Validators.required,
    Validators.email,
  ]);

  language = new FormControl('english');

  title = 'portal-new';
  checked = false;
  indeterminate = true;
  disabled = true;
  value = 'user@';
  constructor(
    iconRegistry: MatIconRegistry,
    translate: TranslateService,
    store: Store,
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
  ) {
    iconRegistry.setDefaultFontSetClass('material-icons-round');
    translate.setDefaultLang('no');
    if (!localStorage.getItem('language')) {
      store.dispatch(changeLanguage({ language: 'no' }));
    }
    // TODO: change this to a single selector
    store.subscribe((state: { language: { language: string } }) => {
      document.documentElement.setAttribute('lang', state.language.language);
      translate.use(state.language.language);
    });

    if (this.platform.SAFARI) {
      document.body.classList.add('safari');
    }

    store.dispatch(UserActions.addUser());

    store
      .select(selectCurrentCompany)
      .pipe(
        filter((company) => {
          return !!company;
        }),
        skip(1),
        distinctUntilKeyChanged('id'),
      )

      .subscribe(() => {
        this.zone.run(() => {
          this.router.navigateByUrl(this.router.url, {
            onSameUrlNavigation: 'reload',
          });
        });
      });
  }
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Invalid character' : '';
  }
}
